@import "../utils/color";

.gallery {
  box-shadow: $bottom-shadow;
  padding-bottom: 40px;

  .title {
    font-family: $myriad-pro-semibold;
    margin: 0 0 40px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    box-shadow: $box-shadow;
  }

  .carousel-item {
    text-align: center;

    img {
      @include respond(xs, sm) {
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }

   .carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
   }
}
