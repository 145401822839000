@import "../utils/color";
@import "../utils/media-query";
@import "../utils/transition";

.sidenav {
  background-color: $cod-gray;
  display: inline;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  z-index: 2;
  transition: $slow;

  &.open {
    width: 100%;
  }

  .close-sidenav-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 32px;
    color: $white;
    transition: $fast;

    &:hover {
      color: $blaze-orange;
    }
  }

  ul {
    list-style: none;

    li {
      padding: 15px 0;

      a {
        color: $white;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          color: $blaze-orange;
        }
      }
    }
  }
}
