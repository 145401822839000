$blaze-orange: #FF6200;
$vermilion: #FF400D;
$white: #FFFFFF;
$gallery: #efefef;
$dusty-gray: #999999;
$mine-shaft: #333333;
$cod-gray: #1A1A1A;

$crusta: #FF833F;
$hit-pink: #FFAC7F;
$romantic: #FFD5BF;
$linen: #F9E2D7;

$transparent: rgba(0, 0, 0, 0);
