@import "../utils/shadow";
@import "../utils/color";
@import "../utils/media-query";
@import "../utils/transition";

footer {
  .footer {
    background-color: $mine-shaft;
    color: $white;
    padding: 32px 0;

    .footer-layout {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 2fr 2fr 1fr;

      @include respond(xs, sm) {
        grid-template-rows: 1fr 1fr auto;
        grid-template-columns: repeat(2, 1fr);

        & > * {
          padding: 16px 0;
        }
      }

      @include respond-to(xs) {
        grid-template-columns: repeat(2, auto);
      }

      h4 {
        font-weight: bold;
        margin: 0 0 16px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 2px 0;

          a {
            color: $gallery;
            transition: $fast;
            font-size: 0.875rem;
            text-decoration: none;

            &:hover {
              color: $blaze-orange;
            }
          }

          small {
            color: $gallery;
            font-family: $myriad-pro-regular;
            font-size: 0.875rem;
          }
        }
      }

      .logo {
        text-align: center;

        @include respond(xs, sm) {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .button {
          width: 100%;
          margin-bottom: 16px;

          @include respond(xs, sm) {
            width: auto;
          }
        }

        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .copyright {
    background-color: $blaze-orange;
    color: $white;
    box-shadow: $box-shadow;
    text-align: center;
    padding: 18px 0;
  }
}
