@import "../base/typography";
@import "../utils/shadow";
@import "../utils/color";
@import "../utils/media-query";

section.services {
  margin: 80px 0;

  .title {
    font-family: $myriad-pro-semibold;
    margin: 0 0 40px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    box-shadow: $box-shadow;
  }

  .services-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 10px;

    @include respond(sm, md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 10px;
    }

    @include respond-to(xs) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      row-gap: 10px;
    }

    .service {
      perspective: 1000px;
      height: 150px;
      cursor: pointer;

      @include respond(md, xl) {
        &:hover {
          .service-inner {
            transform: rotateY(180deg);
          }
        }
      }

      @include respond(xs, sm) {
        &.flip {
          .service-inner {
            transform: rotateY(180deg);
          }
        }
      }

      .service-inner {
        position: relative;
        transition: transform 0.8s;
        transform-style: preserve-3d;
        width: 100%;
        height: 100%;
        color: $white;
        text-align: center;

        .front {
          padding: 0 20px 20px;
          background-color: $blaze-orange;
          box-shadow: $box-shadow;
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;

          .name {
            margin: 0;
            font-size: 1rem;
            font-weight: normal;
          }

          img {
            @include respond-to(xs) {
              width: 100%;
              max-width: 120px;
              height: auto;
            }
          }
        }

        .back {
          padding: 20px;
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          box-shadow: $box-shadow;
          transform: rotateY(180deg);
          background-color: $mine-shaft;
          color: $white;

          .name {
            margin: 0;
            font-size: 1rem;
            font-weight: normal;
          }

          .description {
            font-family: $myriad-pro-light;
            font-size: 0.85rem;
          }
        }
      }
    }
  }
}
