@import "../base/typography";
@import "../utils/color";
@import "../utils/shadow";
@import "../utils/transition";

nav {
  box-shadow: $bottom-shadow;
  position: relative;

  .contact-us {
    box-shadow: $bottom-shadow;
    transition: $slow;
    background-color: $blaze-orange;
    color: $white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 32px 8px 24px;
    font-size: 0.875rem;
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;

    @include respond-to(xs) {
      padding: 8px;
    }

    &.hide {
      top: -54px;
      opacity: 0;
    }

    .contact-us-grid {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(3, auto);

      .phone-icon {
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center;
        font-size: 2rem;

        a {
          display: block;
          line-height: 0;
        }
      }

      .phone {
        font-family: $myriad-pro-light;
      }

      .email {
        grid-row-start: 2;
        grid-row-end: 3;
        font-family: $myriad-pro-light;
      }

      a {
        color: $white;
        text-decoration: none;
        margin-right: 16px;
        transition: $fast;

        &:hover {
          color: $linen;
        }
      }

      .button {
        grid-row-start: 1;
        grid-row-end: 3;
        align-self: center;
        margin: 0;

        @include respond-to(xs) {
          display: none;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }

  .navigation {
    background-color: $white;
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    z-index: 1;

    img {
      height: 84px;

      @include respond(xs, md) {
        height: 64px;
      }
    }

    .open-sidenav-btn {
      display: none;
      font-size: 32px;
      color: $black;
      transition: $fast;

      @include respond(xs, md) {
        display: inline;
      }

      &:hover {
        color: $dusty-gray;
      }
    }

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;

      @include respond(xs, md) {
        display: none;
      }

      li {
        padding: 0;

        a {
          display: block;
          color: $mine-shaft;
          padding: 8px 16px;
          text-decoration: none;
          transition: $fast;
          position: relative;
          font-weight: bold;

          &:after {
            content: "";
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 12px;
            right: 12px;
            background-image: linear-gradient($cod-gray, $cod-gray),
              linear-gradient(transparent, transparent);
            background-size: 0 1px, auto;
            background-repeat: no-repeat;
            background-position: center bottom;
            transition: $fast;
          }

          &:hover:after {
            background-size: 100% 1px, auto;
          }
        }
      }
    }
  }
}
