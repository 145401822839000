@import "../base/typography";
@import "../utils/color";
@import "../utils/media-query";
@import "../utils/shadow";

section.about-us {
  color: $gallery;
  display: grid;
  grid-template-columns: 52% 48%;
  grid-template-rows: 1fr;

  @include respond(xs, sm) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .who-are-us-container {
    background-color: $mine-shaft;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 64px 32px;

    .title {
      color: $blaze-orange;
      text-align: center;
      text-transform: uppercase;
      font-family: $myriad-pro-semibold;
      text-shadow: $text-shadow;
      margin-top: 0;
    }

    .summary {
      font-family: $myriad-pro-light;
      text-indent: 2em;

      @include respond-to(xs) {
        text-align: center;
      }
    }
  }

  .experience-container {
    background-color: $blaze-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 32px;

    .experience-content {
      text-align: center;

      .golden-numbers-container {
        display: inline-block;
        background-color: #000;
        padding: 24px;
        border-radius: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
        text-align: center;
      }

      .golden-numbers {
        font-size: 6rem;
        font-weight: bold;
        background: linear-gradient(
          90deg,
          #ffd700,
          #ffac33,
          #f9e79f,
          #c49a02,
          #ffd700
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
        display: inline-block;
      }

      .experience-text {
        font-size: 1.25rem;
        font-weight: 300;
        margin: 0;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
        font-weight: bold;
      }

      .summary {
        font-family: $myriad-pro-regular;
      }
    }
  }
}
