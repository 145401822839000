@import "../utils/color";
@import "../utils/transition";

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 400px;
    opacity: 0.25;
    cursor: pointer;
    transition: all $fast !important;

    &:hover {
      opacity: 0.5;
    }

    &.swiper-slide-active {
      opacity: 1;
      cursor: default;
      border: 1px solid $dusty-gray;

      &:hover {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
