@import "../base/typography";
@import "../utils/shadow";
@import "../utils/color";
@import "../utils/transition";

.button {
  background-color: $mine-shaft;
  border: none;
  color: $white;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  height: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color $fast, transform $fast;
  font-family: $myriad-pro-regular;
  padding: 0 20px;
  box-shadow: $box-shadow;

  &.transparent {
    background-color: $transparent;
    border: 1px solid $white;
  }

  &:hover {
    background-color: $dusty-gray;
  }

  &:focus:not(:active) {
    outline: solid 1px $blaze-orange;
    outline-offset: 1px;
  }

  &:focus:active {
    outline: none;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }
}
