@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('../fonts/myriad-pro-regular.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('../fonts/myriad-pro-condensed.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('../fonts/myriad-pro-light.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('../fonts/myriad-pro-semibold.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('../fonts/myriad-pro-bold.woff') format('woff');
}

$myriad-pro-regular: 'Myriad Pro Regular';
$myriad-pro-condensed: 'Myriad Pro Condensed';
$myriad-pro-light: 'Myriad Pro Light';
$myriad-pro-semibold: 'Myriad Pro Semibold';
$myriad-pro-bold: 'Myriad Pro Bold';
