@import "typography";
@import "../utils/color";

html {
  scroll-behavior: smooth;

  body {
    color: $mine-shaft;
    background-color: $white;
    font-family: $myriad-pro-regular;
    font-size: 16px;
  }
}
