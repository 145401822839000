@import "../base/typography";
@import "../utils/shadow";
@import "../utils/color";

section.brands {
  .title-container {
    background-color: $blaze-orange;
    color: $white;
    padding: 55px 0 60px;
    text-align: center;
    box-shadow: $bottom-shadow;

    .title {
      font-weight: normal;
      font-family: $myriad-pro-semibold;
      text-transform: uppercase;
      margin: 0;
    }

    hr {
      margin: 8px 0 12px;
    }

    p {
      font-family: $myriad-pro-regular;
      margin: 0;
    }
  }

  .brands-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    padding: 40px 0;

    @include respond(xs, sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    img {
      padding: 15px;
      max-width: 100%;
      height: auto;
      align-self: center;
      justify-self: center;
    }
  }
}
