@import "../utils/color";
@import "../utils/transition";

.input {
  background-color: $transparent;
  border: none;
  border-bottom: 1px solid $dusty-gray;
  border-radius: 0;
  color: $gallery;
  height: 32px;
  padding: 0 8px;
  transition: $fast;

  &:hover {
    border-color: $crusta;
  }

  &:focus {
    border-color: $blaze-orange;
    outline: none;
  }
}
