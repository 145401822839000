@import "../utils/color";
@import "../utils/media-query";
@import "../utils/shadow";

section.home {
  background-image: url("../img/hero-desktop-01.png");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
  filter: brightness(0.8) contrast(1.2);
  padding-top: 155px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);

  @include respond(xs, sm) {
    background-image: none;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    padding-top: 135px;
  }

  .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-align: center;
    min-height: 60vh;

    @include respond(xs, sm) {
      background-image: url("../img/hero-mobile-01.png");
      background-repeat: no-repeat;
      background-position: center left;
      background-size: cover;
      text-align: center;
      min-height: 75vh;
    }

    .title {
      text-transform: uppercase;
      text-shadow: $text-shadow;
    }

    .subtitle {
      font-family: $myriad-pro-light;
    }
  }
}
