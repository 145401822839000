section.contact-us {
  background: url("../img/hero-desktop-02.png");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
  filter: brightness(0.8) contrast(1.2);
  color: $gallery;

  .contact-us-content {
    max-width: 562px;
    padding: 160px 0;

    p {
      font-size: 1.25rem;
      font-family: $myriad-pro-light;
      margin: 0;
      text-transform: uppercase;
      max-width: 254px;
      padding-bottom: 20px;

      .bold {
        font-family: $myriad-pro-semibold;
      }
    }
  }
}
