@import "../utils/color";

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background: $dusty-gray;
  box-shadow: inset 0 0 6px rgba(185, 185, 185, 0.5);
}
